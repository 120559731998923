import React, { useState, useEffect, useRef } from "react";
import bgVideo from "../../images/bg-video-2.mp4";

const LazyLoadVideo = () => {
  const [videoLoaded, setVideoLoaded] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      video
        .play()
        .then(() => {
          setVideoLoaded(true);
        })
        .catch((error) => {
          setVideoLoaded(false);
          console.error("Video failed to play automatically:", error);
        });
    }
  }, []);

  return (
    <div className="video-background">
      {videoLoaded ? (
        <video ref={videoRef} autoPlay muted loop id="bg-video">
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      ) : (
        <div className="video-placeholder" />
      )}
    </div>
  );
};

export default LazyLoadVideo;
