import React from "react";
import comma from "../../images/double-comma.svg";
import ReactMarkdown from "react-markdown";

const Review = ({ data, isMobileView }) => {
  const { testimonials, customers } = data;

  return (
    <div className="ouraprchSec" id="successstories">
      <div className={`${isMobileView ? "ourappWrap p-0" : "ourappWrap pt-1"}`}>
        <div className="successStoriesImages row pb-4">
          <div
            className={`${
              isMobileView
                ? "font-size-14 line-height-20 font-weight-400 whoarewe-color-text  py-5 mt-5"
                : "whoarewe-title whoarewe-color-text  head-title"
            }`}
          >
            {customers.customersQuote}
          </div>

          <div className="d-flex justify-content-between flex-wrap gap-4">
            {testimonials?.map((testy) => (
              <div
                className={`${
                  isMobileView
                    ? " home-card-style col-12"
                    : "home-card-style col-5"
                }`}
              >
                <div>
                  <img
                    src={testy.imageUrl}
                    className="mx-4 mt-3 branas"
                    alt="Branas"
                    width="142"
                    height="43"
                  />
                  <div
                    className={`${
                      isMobileView
                        ? "font-size-14 line-height-20 font-weight-400 text-light p-3"
                        : "whoarewe-desc text-light p-5"
                    }`}
                  >
                    <img src={comma} alt="comma" width="40" height="32" />
                    <br />
                    <ReactMarkdown className="mt-3 ms-2">
                      {testy.content.data.content}
                    </ReactMarkdown>
                  </div>
                </div>

                <div className="d-flex px-2 pb-5 p-md-5">
                  <span
                    className={`${
                      isMobileView
                        ? "col-12 font-size-14 line-height-20 font-weight-400  green  mt-3 ms-3"
                        : "whoarewe-desc2 green  mt-3 ms-3"
                    }`}
                  >
                    {testy.name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
