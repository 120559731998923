import React from "react";

import lofbergs from "../images/lofbergs.svg";
import silverrail from "../images/silverrail.svg";
import klarna from "../images/kalarna.svg";
import cag from "../images/cag.svg";
import politico from "../images/politico.svg";
import branas from "../images/branas.svg";
import opus from "../images/opus.svg";
import fcm from "../images/fcm.svg";
import savr from "../images/savr.svg";
import iotcomm from "../images/iotcomm.svg";
import cap from "../images/cap.svg";
import valbruna from "../images/valbruna.svg";
import kwikexpense from "../images/kwikexpense.svg";
import motille from "../images/motille.svg";
import innision from "../images/innision.svg";
import invencon from "../images/invencon.svg";
import wenmec from "../images/wenmec.svg";
import energy from "../images/energy.svg";
import medoma from "../images/medoma.svg";
import adverty from "../images/adverty.svg";
import Eow from "../images/eraofwe.svg";
import easytryck from "../images/easytryck.svg";
import forlaget from "../images/forlaget.svg";
import calamo from "../images/calamo.svg";

const ClientCompany = ({ data, isMobileView }) => {
  return (
    <div className="ouraprchSec" id="successstories">
      <div className={` ${isMobileView ? "ourappWrap pb-0 " : " "}`}>
        <div
          className={`max-1400  ${
            isMobileView
              ? "successStoriesImages m-0 text-center"
              : "successStoriesImages row"
          }`}
        >
          <div>
            <p
              className={`${
                isMobileView
                  ? "mb-0 ms-3 taperzoid text-uppercase  font-size-9 line-height-20 font-weight-400"
                  : "mb-0 ms-3 taperzoid text-uppercase  font-size-24 line-height-20 font-weight-700"
              }`}
            >
              {data.someCustomers}
            </p>
          </div>
          <div className="img-background">
            <div
              className={`${
                isMobileView ? "inside_stories " : "inside_stories mt-4"
              }`}
            >
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://en.lofbergs.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-3"
                    alt="lofbergs"
                    placeholder="blurred"
                    src={lofbergs}
                    quality={95}
                    objectFit="contain"
                    width={isMobileView ? "69" : "139"}
                    height={isMobileView ? "28" : "56"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3 text-center">
                <a
                  rel="noopener"
                  href="https://www.silverrailtech.com/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mb-2"
                    id="silverrail"
                    alt="SilverRail"
                    src={silverrail}
                    loading="lazy"
                    width={isMobileView ? "112" : "233"}
                    height={isMobileView ? "17" : "34"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.klarna.com/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-2"
                    id="klarna"
                    alt="klarna"
                    src={klarna}
                    loading="lazy"
                    width={isMobileView ? "64" : "129"}
                    height={isMobileView ? "24" : "49"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a rel="noopener" href="https://www.cag.se/" target="_blank">
                  <img
                    className="successStoriesImg mt-3"
                    id="cag"
                    alt="cag"
                    src={cag}
                    loading="lazy"
                    width={isMobileView ? "42" : "84"}
                    height={isMobileView ? "20" : "40"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.politico.com/"
                  target="_blank"
                >
                  <img
                    className={
                      isMobileView
                        ? "successStoriesImg mt-3"
                        : "successStoriesImg"
                    }
                    alt="politico"
                    loading="lazy"
                    src={politico}
                    width={isMobileView ? "67" : "134"}
                    height={isMobileView ? "14" : "28"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.branas.se/om-branasgruppen/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg"
                    alt="branas"
                    src={branas}
                    loading="lazy"
                    width={isMobileView ? "121" : "142"}
                    height={isMobileView ? "21" : "43"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a rel="noopener" href="https://www.opus.se/" target="_blank">
                  <img
                    className="successStoriesImg mt-1"
                    id="opus"
                    alt="Opus"
                    loading="lazy"
                    src={opus}
                    width={isMobileView ? "100" : "199"}
                    height={isMobileView ? "16" : "32"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.fcmtravel.com/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-3 mx-2"
                    id="fcm"
                    alt="fcm"
                    loading="lazy"
                    src={fcm}
                    width={isMobileView ? "67" : "100"}
                  />
                </a>
              </div>
              <div className="m-0">
                <a rel="noopener" href="https://savr.com/" target="_blank">
                  <img
                    className={
                      isMobileView
                        ? "successStoriesImg mt-2"
                        : "successStoriesImg"
                    }
                    id="savr"
                    alt="SAVR"
                    loading="lazy"
                    src={savr}
                    width="100%"
                  />
                </a>
              </div>

              <div className="m-0 pe-3">
                <a rel="noopener" href="https://iotcomms.io/" target="_blank">
                  <img
                    className="successStoriesImg mt-2"
                    id="iotcomm"
                    alt="iotcomm"
                    loading="lazy"
                    src={iotcomm}
                    width={isMobileView ? "67" : "134"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a rel="noopener" href="https://capriga.se/" target="_blank">
                  <img
                    className="successStoriesImg mb-2"
                    id="cap"
                    alt="cap"
                    loading="lazy"
                    src={cap}
                    width={isMobileView ? "67" : "134"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.valbrunanordic.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-2"
                    id="valbruna"
                    alt="valbruna"
                    loading="lazy"
                    src={valbruna}
                    width={isMobileView ? "67" : "134"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a rel="noopener" href="https://www.kwick.io/" target="_blank">
                  <img
                    className="successStoriesImg mt-2"
                    id="kwikexpense"
                    alt="kwikexpense"
                    loading="lazy"
                    src={kwikexpense}
                    width={isMobileView ? "67" : "134"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.motillo.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="motille"
                    alt="motille"
                    loading="lazy"
                    src={motille}
                    width={isMobileView ? "67" : "134"}
                  />
                </a>
              </div>
              <div className="m-0">
                <a
                  rel="noopener"
                  href="https://www.inission.com/sv/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="innision"
                    alt="innision"
                    loading="lazy"
                    src={innision}
                    width={isMobileView ? "100" : "200"}
                  />
                </a>
              </div>
              <div className="m-0">
                <a
                  rel="noopener"
                  href="https://www.invencon.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="invencon"
                    alt="invencon"
                    loading="lazy"
                    src={invencon}
                    width={isMobileView ? "100" : "200"}
                  />
                </a>
              </div>
              <div className="m-0">
                <a
                  rel="noopener"
                  href="https://wenmec.se/en/home/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="wenmec"
                    alt="wenmec"
                    loading="lazy"
                    src={wenmec}
                    width={isMobileView ? "100" : "200"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://kristinehamnsenergi.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="energy"
                    alt="energy"
                    loading="lazy"
                    src={energy}
                    width={isMobileView ? "75" : "120"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.medoma.com/se"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="medoma"
                    alt="medoma"
                    loading="lazy"
                    src={medoma}
                    width={isMobileView ? "75" : "120"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a rel="noopener" href="https://adverty.com/" target="_blank">
                  <img
                    className="successStoriesImg mt-4"
                    id="adverty"
                    alt="adverty"
                    loading="lazy"
                    src={adverty}
                    width={isMobileView ? "75" : "120"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.eraofwe.com/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg mt-4"
                    id="eraofwe"
                    loading="lazy"
                    alt="eraofwe"
                    src={Eow}
                    width={isMobileView ? "75" : "120"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.easytryck.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg  mt-3"
                    id="easytryck"
                    loading="lazy"
                    alt="easytryck"
                    src={easytryck}
                    width={isMobileView ? "100" : "150"}
                  />
                </a>
              </div>

              <div className="m-0 pe-3">
                <a
                  rel="noopener"
                  href="https://www.okforlaget.se/"
                  target="_blank"
                >
                  <img
                    className="successStoriesImg  mt-4"
                    alt="forlaget"
                    loading="lazy"
                    src={forlaget}
                    width={isMobileView ? "100" : "200"}
                  />
                </a>
              </div>
              <div className="m-0 pe-3">
                <a rel="noopener" href="https://calamo.se/" target="_blank">
                  <img
                    className="successStoriesImg mt-4"
                    id="calamo"
                    loading="lazy"
                    alt="calamo"
                    src={calamo}
                    width={isMobileView ? "100" : "120"}
                  />
                </a>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCompany;
