import React, { useState, useEffect } from "react";

const ApproachBusiness = ({ isMobileView, data }) => {
  const { approachTitle, approachDescription, approachBulletPoints } = data;

  return (
    <div className="ouraprchSec" id="successstories">
      <div className={`${isMobileView ? "ourappWrap pb-0 " : "ourappWrap"}`}>
        <div
          className={`${
            isMobileView
              ? "successStoriesImages "
              : "successStoriesImages row py-5"
          }`}
        >
          <div className="whoarewe-title whoarewe-color-text  mb-5">
            {approachTitle}
          </div>

          <div className={`${isMobileView ? " " : "row"}`}>
            <div className={`${isMobileView ? "col-12" : "col-6"}`}>
              <div className={`${isMobileView ? " " : "row"}`}>
                <p
                  className={`${
                    isMobileView
                      ? "font-size-14 line-height-20 font-weight-400 text-light  "
                      : "whoarewe-desc text-light  "
                  }`}
                >
                  {approachDescription}
                </p>
                <ul
                  className={`${
                    isMobileView
                      ? "text-center text-light dot-points p-4"
                      : "text-light dot-points ms-3 py-4"
                  }`}
                >
                  {approachBulletPoints?.map((point) => (
                    <li>
                      <p
                        className={`${
                          isMobileView
                            ? "font-size-14 line-height-20 font-weight-400 text-start  line-height-20"
                            : "whoarewe-desc  line-height-20"
                        }`}
                      >
                        {point.title}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproachBusiness;
